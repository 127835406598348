import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import {
  getTrackingInfoForBookButtonClick,
  getTrackingInfoForNavigateToServicePageClick,
} from '@wix/bookings-analytics-adapter';
import { CatalogServiceDto, ServiceType } from '@wix/bookings-uou-types';
import {
  bookingsBookItClick,
  bookingsCantBookGroupsMessage,
} from '@wix/bi-logger-wixboost-ugc/v2';
import {
  BookingValidations,
  getNotifyPricingPlanRequest,
} from '../../utils/validation/booking-validations';
import { Navigation } from '../../utils/navigation/navigation';
import { ServiceIntent } from '../../utils/navigation/navigation.const';
import { BookingsApi } from '../../api/BookingsApi';
import { WidgetConfig } from '../../../legacy/types';
import {
  ActionNames,
  BiServiceType,
  ReferralInfo,
} from '../../utils/bi/consts';
import { WidgetViewModel } from '../../viewModel/viewModel';
import { getResourceQueryParam } from '../../utils/queryParams/queryParams';
import {
  DialogType,
  ServicePreferencesDialogViewModel,
} from '../../viewModel/dialogViewModel/dialogViewModel';
import { AsyncComponentDataStatus } from '@wix/bookings-services-preferences-modal/types';
import {
  isServiceDynamicPricing,
  mapServiceToServiceV2,
} from '../../utils/serviceDetails/serviceDetails';
import { getLocationId } from '../../utils/getLocationId/getLocationId';

export type NavigationAction = (
  service: CatalogServiceDto,
  referralInfo: ReferralInfo,
) => void;

export type HandelNavigationAction = ({
  service,
  intent,
  referralInfo,
  locationId,
}: {
  service: CatalogServiceDto;
  intent: ServiceIntent;
  referralInfo: ReferralInfo;
  locationId?: string;
}) => void;

export const createHandelNavigationAction = ({
  widgetViewModel,
  wixSdkAdapter,
  bookingsApi,
  flowAPI,
  config,
  setProps,
}: {
  widgetViewModel: WidgetViewModel;
  wixSdkAdapter: WixOOISDKAdapter;
  bookingsApi: BookingsApi;
  flowAPI: ControllerFlowAPI;
  config: WidgetConfig;
  setProps: Function;
}): HandelNavigationAction => {
  return ({
    service,
    intent,
    referralInfo,
  }: {
    service: CatalogServiceDto;
    intent: ServiceIntent;
    referralInfo: ReferralInfo;
  }) => {
    const { controllerConfig, bi, experiments } = flowAPI;
    const { compId } = controllerConfig;
    const bookingsValidations = new BookingValidations(
      wixSdkAdapter,
      bookingsApi,
      flowAPI.experiments.enabled('specs.bookings.V1Shutdown'),
    );
    const navigation = new Navigation(wixSdkAdapter, compId, experiments);
    const activeFeatures = JSON.parse(config.activeFeatures);

    const isServiceListBookButtonLoaderEnabled = experiments.enabled(
      'specs.bookings.serviceListBookButtonLoader',
    );

    if (isServiceListBookButtonLoaderEnabled) {
      widgetViewModel.navigatingToService = service.id;
      setProps({
        widgetViewModel: {
          ...widgetViewModel,
        },
      });
    }
    bookingsValidations
      .shouldNavigate(service, activeFeatures, intent)
      .then(async ({ canBook, reason }) => {
        if (canBook) {
          const data = {
            service,
            businessName: config.businessInfo.name!,
          };
          const trackingInfo =
            intent === ServiceIntent.BOOK_OFFERING
              ? getTrackingInfoForBookButtonClick(data)
              : getTrackingInfoForNavigateToServicePageClick(data);
          wixSdkAdapter.trackAnalytics(trackingInfo);

          bi?.report(
            bookingsBookItClick({
              service_id: service.id,
              type: BiServiceType[service.type],
              isPendingApproval: service.schedulePolicy.isPendingApprovalFlow,
              referralInfo,
              actionName: ActionNames[intent],
            }),
          );

          const queryParam = getResourceQueryParam(wixSdkAdapter);
          const locationId = getLocationId(flowAPI, widgetViewModel);

          if (
            experiments.enabled('specs.bookings.multiServiceAppointment') &&
            !!config.businessInfo?.isMultiServicesAppointmentsEnable &&
            service.type === ServiceType.INDIVIDUAL
          ) {
            const serviceV2 = mapServiceToServiceV2({ service });

            const servicePreferencesDialogViewModel: ServicePreferencesDialogViewModel =
              {
                isOpen: true,
                type: DialogType.ServicePreferences,
                data: {
                  services: {
                    status: AsyncComponentDataStatus.LOADING,
                  },
                  resources: {
                    status: AsyncComponentDataStatus.LOADING,
                  },
                  inView: {
                    service: serviceV2,
                    serviceOptionsAndVariants: isServiceDynamicPricing(
                      serviceV2,
                    )
                      ? {
                          status: AsyncComponentDataStatus.LOADING,
                        }
                      : {
                          status: AsyncComponentDataStatus.IDLE,
                          data: undefined,
                        },
                    preselectedValues: {
                      locationId,
                      ...(queryParam.resource
                        ? {
                            resources: {
                              staff: [queryParam.resource],
                            },
                          }
                        : {}),
                    },
                  },
                  regionalSettingsLocale: config.regionalSettings!,
                },
              };

            widgetViewModel.dialogViewModel = servicePreferencesDialogViewModel;

            setProps({
              widgetViewModel: {
                ...widgetViewModel,
              },
            });

            const { afterModalOpen } = await import(
              '@wix/bookings-services-preferences-modal/utils'
            );

            afterModalOpen({
              service: serviceV2,
              httpClient: flowAPI.httpClient,
              instance: flowAPI.controllerConfig.appParams.instance,
              onDataLoaded: ({
                resources,
                serviceResponse,
                servicesResponse,
                serviceOptionsAndVariants,
              }: any) => {
                if (resources) {
                  servicePreferencesDialogViewModel.data.resources = {
                    data: resources,
                    status: AsyncComponentDataStatus.IDLE,
                  };
                }
                if (serviceResponse) {
                  servicePreferencesDialogViewModel.data.inView.service =
                    serviceResponse.service!;
                }
                if (servicesResponse) {
                  servicePreferencesDialogViewModel.data.services = {
                    data: servicesResponse.services!,
                    status: AsyncComponentDataStatus.IDLE,
                  };
                }

                if (serviceOptionsAndVariants) {
                  servicePreferencesDialogViewModel.data.inView.serviceOptionsAndVariants =
                    {
                      status: AsyncComponentDataStatus.IDLE,
                      data: serviceOptionsAndVariants,
                    };
                }

                setProps({ widgetViewModel: { ...widgetViewModel } });
              },
            });
          } else {
            await navigation.navigateToApp({
              service,
              intent,
              location: locationId,
              ...queryParam,
            });
          }
        } else {
          if (reason) {
            try {
              if (reason.premiumError) {
                await bookingsApi.notifyOwnerNonPremiumEnrollmentAttempt();
              } else if (reason.pricingPlanError) {
                await bookingsApi.notifyOwnerNonPricingPlanEnrollmentAttempt(
                  getNotifyPricingPlanRequest(service, reason),
                );
              }
            } catch (error) {
              console.error(error);
            }
          }

          bi?.report(
            bookingsCantBookGroupsMessage({
              referralInfo: ReferralInfo.WIDGET,
              failReason: reason?.siteNotEcomError
                ? 'non ecom site'
                : undefined,
            }),
          );

          if (
            flowAPI.experiments.enabled('specs.bookings.GenericDialogComponent')
          ) {
            widgetViewModel.dialogViewModel.isOpen = true;
            widgetViewModel.dialogViewModel.type = DialogType.Premium;
          } else {
            widgetViewModel.showDialog = true;
          }
        }
      })
      .catch(console.error)
      .finally(() => {
        if (isServiceListBookButtonLoaderEnabled) {
          widgetViewModel.navigatingToService = '';
        }
        setProps({
          widgetViewModel: {
            ...widgetViewModel,
          },
        });
      });
  };
};
