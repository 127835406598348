import { CatalogServiceDto } from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { WidgetViewModel } from '../../viewModel/viewModel';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { getResourceQueryParam } from '../../utils/queryParams/queryParams';
import { getLocationId } from '../../utils/getLocationId/getLocationId';
import { AsyncComponentDataStatus } from '@wix/bookings-services-preferences-modal/types';
import { WidgetConfig } from '../../../legacy/types';
import {
  isServiceDynamicPricing,
  mapServiceToServiceV2,
} from '../../utils/serviceDetails/serviceDetails';
import {
  DialogType,
  ServicePreferencesDialogViewModel,
} from '../../viewModel/dialogViewModel/dialogViewModel';

export type openServicesPreferencesModalAction = ({
  service,
}: {
  service: CatalogServiceDto;
}) => Promise<void>;

export const createOpenServicesPreferencesModalAction = ({
  flowAPI,
  widgetViewModel,
  wixSdkAdapter,
  config,
  setProps,
}: {
  flowAPI: ControllerFlowAPI;
  widgetViewModel: WidgetViewModel;
  wixSdkAdapter: WixOOISDKAdapter;
  config: WidgetConfig;
  setProps: Function;
}): openServicesPreferencesModalAction => {
  return async ({ service }) => {
    const queryParam = getResourceQueryParam(wixSdkAdapter);
    const locationId = getLocationId(flowAPI, widgetViewModel);
    const serviceV2 = mapServiceToServiceV2({ service });

    const servicePreferencesDialogViewModel: ServicePreferencesDialogViewModel =
      {
        isOpen: true,
        type: DialogType.ServicePreferences,
        data: {
          services: {
            status: AsyncComponentDataStatus.LOADING,
          },
          resources: {
            status: AsyncComponentDataStatus.LOADING,
          },
          inView: {
            service: serviceV2,
            serviceOptionsAndVariants: {
              status: isServiceDynamicPricing(serviceV2)
                ? AsyncComponentDataStatus.LOADING
                : AsyncComponentDataStatus.IDLE,
              data: undefined,
            },
            preselectedValues: {
              locationId,
              ...(queryParam.resource
                ? {
                    resources: {
                      staff: [queryParam.resource],
                    },
                  }
                : {}),
            },
          },
          regionalSettingsLocale: config.regionalSettings!,
        },
      };

    widgetViewModel.dialogViewModel = servicePreferencesDialogViewModel;
    widgetViewModel.navigatingToService = '';
    setProps({
      widgetViewModel: {
        ...widgetViewModel,
      },
    });

    const { afterModalOpen } = await import(
      '@wix/bookings-services-preferences-modal/utils'
    );

    afterModalOpen({
      service: serviceV2,
      httpClient: flowAPI.httpClient,
      instance: flowAPI.controllerConfig.appParams.instance,
      onDataLoaded: ({
        resources,
        serviceResponse,
        servicesResponse,
        serviceOptionsAndVariants,
      }: any) => {
        if (resources) {
          servicePreferencesDialogViewModel.data.resources = {
            data: resources,
            status: AsyncComponentDataStatus.IDLE,
          };
        }
        if (serviceResponse) {
          servicePreferencesDialogViewModel.data.inView.service =
            serviceResponse.service!;
        }
        if (servicesResponse) {
          servicePreferencesDialogViewModel.data.services = {
            data: servicesResponse.services!,
            status: AsyncComponentDataStatus.IDLE,
          };
        }

        if (serviceOptionsAndVariants) {
          servicePreferencesDialogViewModel.data.inView.serviceOptionsAndVariants =
            {
              status: AsyncComponentDataStatus.IDLE,
              data: serviceOptionsAndVariants,
            };
        }

        setProps({ widgetViewModel: { ...widgetViewModel } });
      },
    });
  };
};
