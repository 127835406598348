import { CatalogServiceDto, ServiceType } from '@wix/bookings-uou-types';
import { ReferralInfo } from '../../utils/bi/consts';
import { NavigateToNextPageAction } from '../navigateToNextPage/navigateToNextPage';
import { OnBookFlowStartAttemptAction } from '../onBookFlowStartAttempt/onBookFlowStartAttempt';
import { set } from 'lodash';
import { WidgetViewModel } from '../../viewModel/viewModel';
import { setServiceLoader } from '../../utils/setServiceLoader/setServiceLoader';
import { NavigationTarget } from '../navigateToNextPage/consts';

export type OnActionButtonClickAction = ({
  service,
  referralInfo,
}: {
  service: CatalogServiceDto;
  referralInfo: ReferralInfo;
}) => Promise<void>;

export const createOnActionButtonClickAction = ({
  navigateToNextPage,
  onBookFlowStartAttempt,
  setProps,
  widgetViewModel,
}: {
  navigateToNextPage: NavigateToNextPageAction;
  onBookFlowStartAttempt: OnBookFlowStartAttemptAction;
  setProps: Function;
  widgetViewModel: WidgetViewModel;
}): OnActionButtonClickAction => {
  return async ({
    service,
    referralInfo,
  }: {
    service: CatalogServiceDto;
    referralInfo: ReferralInfo;
  }) => {
    setServiceLoader({ setProps, widgetViewModel, service });

    if (service.type === ServiceType.COURSE) {
      return navigateToNextPage({
        target: NavigationTarget.SERVICE_PAGE,
        service,
        referralInfo,
      });
    } else {
      return onBookFlowStartAttempt({ service, referralInfo });
    }
  };
};
